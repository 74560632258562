import HomeHeader from "./sections/header/HomeHeader";
import "./css/home.css"
import Navbar from "../../components/Navbar";
import Logo from "../../assets/logo.png"
import Portfolios from "./sections/portfolios/Portfolios";
import Footer from "../../components/footer";
import Links from "./data/navItems"
import Socials from "./data/socialItems"

const Home = () => {

    return (
        <div className="home">
            <Navbar entries={Links} logo={Logo} />
            <HomeHeader />
            <Portfolios/>
            <Footer links={Links} socials={Socials}/>
        </div>
    )
}

export default Home