import PortfolioImage from "../../../../assets/portfolios.png"
import "../../css/portfolio.css"
import data from "../../data/portfolios";
import PortfolioEntry from "./PortfolioEntry";

const Portfolios = () => {
    return (
        <section>
            <div className="container portfolio__container">
                <div className="portfolio__header">
                    <div className="portfolio__header-left">
                        <img src={PortfolioImage} alt="Portfolio"/>
                    </div>
                    <div className="portfolio__header-right">
                        <div className="portfolio__header-col">
                            <h1>Portfolios</h1>
                        </div>
                    </div>
                </div>
                <div className="portfolio__content">
                    {
                        data.map(p => (
                            <PortfolioEntry
                                id={p.id}
                                name={p.name}
                                bio={p.bio}
                                image={p.image}
                                link={p.link}
                            />
                        ))
                    }
                </div>
            </div>
        </section>
    );
};

export default Portfolios;