import {PortfolioEntryData} from "../../../types/PortfolioEntryData";
import JonKamisProfile from "../../../assets/jon_kamis_profile.jpg";
import AndrewKamisProfile from "../../../assets/andrew_kamis_profile.jpg"

let portfolios: PortfolioEntryData[] = [
    {
        id: "1",
        image: JonKamisProfile,
        name: "Jonathan Kamis",
        bio: "A backend software engineer and DevOps engineer with over 4 years in the industry.",
        link: "https://jonathan.kamis.dev",
    },

    {
        id: "2",
        image: AndrewKamisProfile,
        name: "Andrew Kamis",
        bio: "An English/Education Major studying at Duquesne University and a published Author",
        link: "https://andrew.kamis.dev",
    },
];

export default portfolios;