import {PortfolioEntryData} from "../../../../types/PortfolioEntryData";
import Card from "../../../../components/Card";

const PortfolioEntry = (props: PortfolioEntryData) => {
    return (
        <div key={props.id} className="portfolio__entry">
            <Card className="portfolio__card" onClick={() => window.location.href = props.link }>

                <div className="portfolio__image">
                    <img src={props.image} alt="Portfolio"/>
                </div>
                <div className="portfolio__item-about">
                    <h1>{props.name}</h1>
                    <h3>{props.bio}</h3>
                </div>

            </Card>
</div>
)
    ;
};

export default PortfolioEntry;