import {LinkEntry} from "../types/LinkEntry";
import {SocialEntry} from "../types/SocialEntry";

interface FooterProps {
    links: LinkEntry[];
    socials: SocialEntry[];
}

const Footer = (props: FooterProps) => {
    return (
        <footer id="footer">
            <div className="container footer__container">
                <ul className="nav__menu">
                    {
                        props.links.map(fLink => <li key={fLink.id}><a href={fLink.link}>{fLink.title}</a></li>)
                    }
                </ul>
                <div className = "footer__socials">
                    {
                        props.socials.map(social => <a key={social.id} href={social.link} target="_blank" rel="noopener noreferrer">{social.icon}</a>)
                    }
                </div>
            </div>
            <div className="footer__copyright">
                <small>2024 JONATHAN KAMIS &copy; All Rights Reserved</small>
            </div>
        </footer>
    )
}

export default Footer