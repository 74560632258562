import {LinkEntry} from "../types/LinkEntry";

interface NavbarProps {
    entries: LinkEntry[];
    logo: string;
}

const Navbar = (props: NavbarProps) => {
    return (
        <nav>
            <div className="container nav__container">
                <a href="index.html" className='nav__logo'>
                    <img src={props.logo} alt="Logo"/>
                </a>
                <ul className="nav__menu">
                    {
                        props.entries.map(i => <li key={i.id}><a href={i.link}>{i.title}</a></li>)
                    }
                </ul>
            </div>
        </nav>
    )
}

export default Navbar