import {LinkEntry} from "../../../types/LinkEntry";

let links: LinkEntry[] = [
    {
        id: "1",
        link: "#portfolios",
        title: "Portfolios",
    },
]

export default links;