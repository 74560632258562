import "./css/card.css"
import React from 'react'

interface CardProps {
    children?: React.ReactNode;
    className?: String;
    onClick?: React.MouseEventHandler;
}

const Card = (props: CardProps) => {
    return (
        <article className={`card ${props.className}`} onClick={props.onClick}>
            {props.children}
        </article>
    )
}

export default Card