import HeaderTitle from "../../../../assets/header_title.png"
const HomeHeader = () => {
    return (
        <header>
            <div className="container header__image">
                <img src = {HeaderTitle} alt="Title Image" />
            </div>
        </header>
    );
};

export default HomeHeader;